* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open-Sans',sans-serif;
  color: #343a4a;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 80px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}